import React from "react";
import Maps from "../components/Maps";

function SVOMaps()
{
    return(
        <div className="MapsBody">
            <Maps/>
        </div>   
    )
}

export default SVOMaps;
